export class Sectors {
  static sectorMap = {
    Healthcare: { long: "Здравоохранение", short: "Здрав." },
    Technology: { long: "IT", short: "IT" },
    Materials: { long: "Сырье", short: "Сырье" },
    "Consumer Goods": { long: "Потребительский", short: "Потреб." },
    Utilities: { long: "Коммунальный", short: "Коммун." },
    Services: { long: "Услуги", short: "Услуги" },
    Financial: { long: "Финансы", short: "Финанс." },
    "Industrial Goods": { long: "Промышленность", short: "Пром." },
    Conglomerates: { long: "Конгломераты", short: "Конгл." },
    Energy: { long: "Энергетика", short: "Энерг." },
    General: { long: "Другое", short: "Другое" },
    "Consumer Cyclical": { long: "Циклические товары", short: "Цикл." },
    "Communication Services": { long: "Телекоммуникации", short: "Телеком." },
    "Real Estate": { long: "Недвижимость", short: "Недвиж." }
  };

  static getAll() {
    let sectors = {};

    for (let sectorName in Sectors.sectorMap) {
      sectors[sectorName] = Sectors.sectorMap[sectorName].long;
    }

    return sectors;
  }

  /**
   * @param { string } sector - Индентификатор
   * @param { Object } params - Параметры
   * @param { "long" | "short" } params.type - Написание
   */
  static getLabel(sector, { type = "long" } = {}) {
    const noSectors = type === "short" ? "Неизв." : "Неизвестный";

    return Sectors.sectorMap[sector]?.[type] ?? noSectors;
  }
}
