import Vue from "vue";

import { DateHelper } from "~/core/date";
import { formatConsensus } from "../filters/consensus";
import { formatNumber, formatBigNumber, formatPercent } from "../filters/numbers";
import { yesNoFilter } from "../filters/yesNo";
import { analystAction } from "../filters/action";
import { Sectors } from "../filters/sectors";

Vue.filter("number", formatNumber);
Vue.filter("bigNumber", formatBigNumber);
Vue.filter("percent", formatPercent);

Vue.filter("date", DateHelper.getHumanFriendlyDate);
Vue.filter("dateTime", DateHelper.formatDateTime);

Vue.filter("yesNo", yesNoFilter);
Vue.filter("sectors", Sectors.getLabel);

Vue.filter("consensus", formatConsensus);
Vue.filter("analystAction", analystAction);
