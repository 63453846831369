import Vue from "vue";
import VueRouter from "vue-router";

import PanelLayout from "~/layouts/panel";
import LoginLayout from "~/layouts/login";

import { panelRoutes } from "./routes";

const routes = [
  {
    path: "/",
    component: PanelLayout,
    children: panelRoutes
  },

  {
    path: "/login",
    name: "login",
    component: LoginLayout
  },

  {
    name: "not-found",
    path: "/404",
    alias: "*",
    component: () => import("~/pages/404")
  }
];

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  base: "./",
  routes
});
