export const layoutUserFetch = {
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    userStatus() {
      return this.$store.state.user.status;
    },
    isAuthorized() {
      return this.userStatus === "authorized";
    }
  },
  mounted() {
    this.$store.dispatch("user/fetch", { redirect: true });
  }
};
