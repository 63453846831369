<template>
  <div class="d-inline-flex">
    <v-btn @click="active = !active">
      <v-icon>mdi-cash-plus</v-icon>
    </v-btn>

    <v-bottom-sheet v-model="active" scrollable>
      <picker :active="active" @selected="handleSelect" />
    </v-bottom-sheet>
  </div>
</template>

<script>
import Picker from "./picker";

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false
    };
  },
  methods: {
    handleSelect(stock) {
      this.$emit("selected", stock);

      this.active = false;
    }
  },
  components: {
    Picker
  }
};
</script>
