import { RouteGroup, RouteNavSchema } from "./common";

export const salesRoutes = new RouteGroup({ prefix: "sales" })
  .addRoute({ path: "index", name: "sales-index", component: () => import("~/pages/sales") })
  .addRoute({ path: "create", name: "sales-create", component: () => import("~/pages/sales/create") })
  .addRoute({ path: ":id", name: "sales-id", component: () => import("~/pages/sales/_id") });

export const salesNav = new RouteNavSchema({ icon: "mdi-sale", label: "Распродажи" })
  .addChild({ label: "Список", to: { name: "sales-index" } })
  .addChild({ label: "Создать распродажу", to: { name: "sales-create" } });
