import { RouteGroup, RouteNavSchema } from "./common";

export const mediaRoute = new RouteGroup({ prefix: "media" })
  .addRoute({
    path: "index",
    name: "media-index",
    component: () => import("~/pages/media")
  })
  .addRoute({ path: ":name", name: "media-name", component: () => import("~/pages/media/_name") });

export const mediaNav = new RouteNavSchema({
  icon: "mdi-newspaper",
  label: "СМИ",
  to: { name: "media-index" }
});
