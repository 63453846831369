<template>
  <v-navigation-drawer v-model="drawer" app dark>
    <v-list color="#000" elevation="1">
      <v-list-item>
        <v-list-item-title class="title">
          <router-link :to="{ name: 'panel-index' }">
            <div class="panel-logo">
              <v-img src="@/assets/img/logo.svg" alt="logo" width="188" />
              <div v-if="subImg.current !== null" class="panel-logo__sub-logo" :class="subImgClasses" />
            </div>
          </router-link>
        </v-list-item-title>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-list dark nav dense>
      <main-navigation-link v-for="(link, ind) of links" :key="ind" :link="link" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mainNavigation } from "~/router/routes";
import MainNavigationLink from "./link";

export default {
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      links: [...mainNavigation],

      subImg: {
        current: null,
        max: 5
      }
    };
  },
  computed: {
    drawer: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },

    subImgClasses() {
      return "panel-logo__sub-logo_" + this.subImg.current;
    }
  },
  created() {
    this.changeSubLogo();
  },
  methods: {
    changeSubLogo() {
      const { max } = this.subImg;

      if (max > 0) {
        this.subImg.current = Math.floor(Math.random() * max + 1);
      }
    }
  },
  components: {
    MainNavigationLink
  }
};
</script>

<style lang="scss">
.panel-logo {
  position: relative;

  &__sub-logo {
    position: absolute;
    top: 0;
    right: 0;

    width: 24px;
    height: 24px;

    background-size: 24px 24px;

    &_1 {
      background-image: url(../../../assets/img/autumn/1.svg);
    }

    &_2 {
      background-image: url(../../../assets/img/autumn/2.svg);
    }

    &_3 {
      background-image: url(../../../assets/img/autumn/3.svg);
    }

    &_4 {
      background-image: url(../../../assets/img/autumn/4.svg);
    }

    &_5 {
      background-image: url(../../../assets/img/autumn/5.svg);
    }
  }
}
</style>
