<template>
  <v-snackbar
    v-model="active"
    class="app-snackbar"
    bottom
    right
    :color="snackbar.color"
    multi-line="multi-line"
    :timeout="2000"
  >
    <div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="snackbar.html" />

      <div class="app-snackbar__close">
        <v-btn icon @click="active = false">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </div>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    snackbar() {
      return this.$store.state.snackbar;
    },

    active: {
      get() {
        return this.snackbar.active;
      },
      set(value) {
        if (!value) this.$snackbar.close();
      }
    }
  }
};
</script>

<style lang="scss">
.app-snackbar {
  position: relative;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
