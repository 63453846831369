export class Snackbar {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  success(html) {
    this.dispatch("snackbar/success", html);
  }
  error(html) {
    this.dispatch("snackbar/error", html);
  }
  warn(html) {
    this.dispatch("snackbar/warn", html);
  }
  message(html) {
    this.dispatch("snackbar/message", html);
  }

  close() {
    this.dispatch("snackbar/close");
  }
}
