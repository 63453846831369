import { RouteGroup, RouteNavSchema } from "./common";

export const promocodesRoutes = new RouteGroup({ prefix: "promocodes" })
  .addRoute({ path: "index", name: "promocodes-index", component: () => import("~/pages/promocodes") })
  .addRoute({ path: "create", name: "promocodes-create", component: () => import("~/pages/promocodes/create") })
  .addRoute({ path: ":id", name: "promocodes-id", component: () => import("~/pages/promocodes/_id") });

export const promocodesNav = new RouteNavSchema({ icon: "mdi-gift", label: "Промокоды" })
  .addChild({ label: "Список", to: { name: "promocodes-index" } })
  .addChild({ label: "Создать промокод", to: { name: "promocodes-create" } });
