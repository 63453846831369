import { $auth } from "~/core/auth-module";
import { $axios } from "~/core/axios";

export const user = {
  strict: true,
  namespaced: true,
  state: {
    status: "pending", //  pending | authorized | not_authorized
    user: {
      email: "",
      name: "",
      lastName: "",
      role: ""
    }
  },
  actions: {
    async fetch({ commit }, { redirect = true } = {}) {
      if (!$axios.defaults.headers.common.Authorization) {
        commit("setStatus", "not_authorized");
        return;
      }

      try {
        const user = await $axios.$get("users/info");

        commit("setUser", user);
        commit("setStatus", "authorized");
      } catch {
        $auth.logOut({ redirect });

        throw "Нет доступа";
      }
    }
  },
  mutations: {
    setStatus(state, status) {
      state.status = status;
    },
    setUser(state, user) {
      state.user = user;
    }
  }
};
