<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        placeholder="Время"
        prepend-icon="mdi-clock-time-three-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>

    <v-time-picker v-model="time" format="24hr" use-seconds />
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      validator(value) {
        return typeof value === "string" || value === null;
      }
    }
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    time: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
