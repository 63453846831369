<template>
  <v-app>
    <v-app-bar app color="#000" dark elevation="1">
      <template v-if="isAuthorized">
        <v-app-bar-nav-icon class="d-lg-none" @click="drawer = !drawer" />
        <v-spacer />
        <user-top-menu />
      </template>
    </v-app-bar>

    <main-navigation v-model="drawer" />

    <v-main>
      <v-container v-if="isAuthorized" class="pa-4">
        <router-view />
      </v-container>
    </v-main>

    <app-snackbar />
  </v-app>
</template>

<script>
import { layoutUserFetch } from "~/mixins/layout-user-fetch";

import MainNavigation from "~/components/app/main-navigation";
import UserTopMenu from "~/components/app/header/user-top-menu";

import AppSnackbar from "~/components/app/snackbar";

export default {
  mixins: [layoutUserFetch],
  data() {
    return {
      drawer: null
    };
  },
  created() {
    this.$store.dispatch("fetchDictionaries");
  },
  watch: {
    userStatus(status) {
      if (status === "not_authorized") {
        this.$router.replace({ name: "login" });
      }
    }
  },
  components: {
    MainNavigation,
    UserTopMenu,
    AppSnackbar
  }
};
</script>
