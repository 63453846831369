import { RouteGroup, RouteNavSchema } from "./common";

export const productsRoutes = new RouteGroup({ prefix: "products" }).addRoute({
  path: "index",
  name: "products-index",
  component: () => import("~/pages/products")
});

export const productsNav = new RouteNavSchema({
  icon: "mdi-package",
  label: "Продукты",
  to: { name: "products-index" }
});
