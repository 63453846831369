export class ApiValidationError {
  #e;
  #schema;
  #defaultError = {
    common: "Произошла неизвестная ошибка"
  };

  static compile(e, schema = []) {
    const error = {};

    for (const key of schema) {
      error[key] = e[key] ?? "";
    }

    return error;
  }

  constructor(axiosError, schema = []) {
    this.#e = axiosError;
    this.#schema = schema;

    if (typeof this.#e?.message === "string") {
      this.#defaultError.common += `: ${this.#e.message}`;
    }
  }

  getError() {
    try {
      const errors = this.parseErrors();

      if (Object.values(errors).filter(error => error !== undefined).length === 0) {
        return this.#defaultError;
      }

      return errors;
    } catch {
      return this.#defaultError;
    }
  }

  parseErrors() {
    const errors = {};
    const { data = {} } = this.#e.response;

    for (const key of this.#schema) {
      const value = data[key];

      if (!value) continue;

      const keys = Object.keys(value);
      for (const index of keys) {
        let error = value[index];

        if (typeof error === "string") {
          errors[key] = error;
          continue;
        }

        const keys2 = Object.keys(error);

        for (const index2 of keys2) {
          errors[`${key}[${index}][${index2}]`] = `${key}[${index}]: ${error[index2]}`;
        }
      }
    }

    return errors;
  }

  toHtml() {
    const list = Object.values(this.getError()).reduce((acc, curr) => {
      return curr ? acc + `<li>${curr}</li>` : acc;
    }, "");

    return `<ul>${list}</ul>`;
  }
}
