import axios from "axios";
import qs from "qs";

export const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30e3
});

for (let method of ["request", "delete", "get", "head", "options", "post", "put", "patch"]) {
  $axios["$" + method] = function () {
    return this[method].apply(this, arguments).then(res => res && res.data);
  };
}

$axios.setToken = token => {
  $axios.defaults.headers.common.Authorization = "Bearer " + token;
};

$axios.defaults.paramsSerializer = function (params) {
  return qs.stringify(params);
};

export function injectAuth(origin, $auth) {
  $axios.interceptors.request.use(checkToken);
  $axios.interceptors.response.use(undefined, refreshToken);

  async function checkToken(config) {
    const isRequestToBase = config.url.indexOf(origin) === -1;
    const isTokenExpired = $auth.tokenService.isTokenExpired();

    const needRefreshToken = isRequestToBase && isTokenExpired;

    if (needRefreshToken) {
      throw { config, response: { status: 401 } };
    }

    return config;
  }

  async function refreshToken(error) {
    const { response, config } = error;
    if (response?.status === 401 && !config.__isRetryRequest) {
      const accessToken = await $auth.refreshToken();

      if (accessToken) {
        $axios.setToken(accessToken);

        config.__isRetryRequest = true;
        config.headers.Authorization = `Bearer ${accessToken}`;

        return $axios(config);
      }
    }
    throw error;
  }
}
