import { $axios } from "~/core/axios";

import { Sectors } from "~/core/filters/sectors";

export const forms = {
  strict: true,
  namespaced: true,
  state: {
    stockStatuses: [],
    stockSectors: [],
    stockCountries: [],
    expertInvestmentCompanies: [],
    expertsByType: []
  },
  actions: {
    async fetchStatuses({ state, commit }) {
      if (state.stockStatuses.length) return;

      const statuses = await $axios.$get("references/stock-status");

      commit("setStatuses", statuses);
    },

    async fetchSectors({ state, commit }) {
      if (state.stockSectors.length) return;

      const sectors = await $axios.$get("references/stock-sector");

      sectors.forEach(sector => {
        sector.title = Sectors.getLabel(sector?.value) ?? sector.title;
      });

      commit("setSectors", sectors);
    },

    async fetchCountries({ commit }) {
      const { data: countries } = await $axios.$get("countries", { params: { type: "select" } });

      commit("setCountries", countries);
    },

    async fetchInvestmentCompanies({ commit }, params = {}) {
      const { data } = await $axios.$get("investment-companies", { params: { type: "select", ...params } });

      commit("setInvestmentCompanies", data);
    },

    async fetchExpertsByType({ commit }, { expertType } = {}) {
      const data = await $axios.$get("experts", { params: { expert: expertType, type: "select" } });

      commit("setExpertsByType", data);
    }
  },
  getters: {
    statusTitleByValue: state => value => {
      return state.stockStatuses.filter(status => status.value === value)?.[0]?.title ?? "Нет данных";
    },
    sectorTitleByValue: state => value => {
      return state.stockSectors.filter(sector => sector.value === value)?.[0]?.title ?? "Неизвестно";
    }
  },
  mutations: {
    setStatuses(state, statuses) {
      state.stockStatuses = statuses;
    },
    setSectors(state, sectors) {
      state.stockSectors = sectors;
    },
    setCountries(state, countries) {
      state.stockCountries = countries;
    },
    setInvestmentCompanies(state, investmentCompanies) {
      state.expertInvestmentCompanies = investmentCompanies;
    },
    setExpertsByType(state, expertsByType) {
      state.expertsByType = expertsByType;
    }
  }
};
