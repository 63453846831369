import { $axios } from "~/core/axios";

const unknownProduct = {
  id: -1,
  name: "unknown",
  title: "—"
};

export const dictionary = {
  strict: true,
  namespaced: true,
  state: {
    products: []
  },
  actions: {
    async fetchProducts({ commit }) {
      const products = await $axios.$get("products");

      commit("setProducts", products);
    }
  },
  getters: {
    productById: state => id => {
      return state.products.find(product => product.id === id) ?? unknownProduct;
    }
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    }
  }
};
