import { RouteGroup, RouteNavSchema } from "./common";

export const profileRoutes = new RouteGroup({ prefix: "profile" }).addRoute({
  path: "index",
  name: "profile-index",
  component: () => import("~/pages/profile")
});

export const profileNav = new RouteNavSchema({
  icon: "mdi-account",
  label: "Профиль",
  to: { name: "profile-index" }
});
