import { declOfNum } from "~/core/helpers";

export class DateHelper {
  static months = {
    enum: [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь"
    ],

    shorthand: ["янв.", "фев.", "мар.", "апр.", "мая.", "июн.", "июл.", "авг.", "сен.", "окт.", "ноя.", "дек."],
    longhand: [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря"
    ]
  };

  static parseDate(date) {
    let _date = new Date(date);

    if (typeof date === "string") {
      // Поддержка формата "ДД.ММ.ГГГГ"
      if (date.match(/\d{2}\.\d{2}\.\d{4}/)) {
        date = date.replace(/(\d{2})\.(\d{2})/, "$2.$1");
        _date = new Date(date);
      }

      // Safari
      if (Number.isNaN(_date.getSeconds())) {
        _date = new Date(date.replace(/(\.|-)/g, "/"));
      }
    }

    return _date;
  }

  static formatDate(date, humanFriendly = false) {
    let _date = DateHelper.parseDate(date);

    let year = _date.getFullYear();
    let month = ("0" + parseInt(_date.getMonth() + 1)).slice(-2);
    let day = ("0" + _date.getDate()).slice(-2);

    return humanFriendly ? [day, month, year].join(".") : [year, month, day].join("-");
  }

  static getHumanFriendlyDate(date) {
    if (date === null) return "—";

    return DateHelper.formatDate(date, true);
  }

  /** Прибавить к дате N дней */
  static appendDay(date, days) {
    const _date = new Date(date);
    _date.setHours(0, 0, 0);
    return new Date(_date.setDate(_date.getDate() + days));
  }

  /** Получить возраст */
  static getAge(date) {
    let _date = DateHelper.parseDate(date);

    return new Date(Date.now() - _date).getFullYear() - 1970;
  }

  /** Возвращает количество дней между датами (может быть отрицательным, если date2 < date1) */
  static getDayDiff(date1, date2) {
    date1 = DateHelper.parseDate(date1).setHours(0, 0, 0);
    date2 = DateHelper.parseDate(date2).setHours(0, 0, 0);

    let diff = (date2 - date1) / 86400000;

    return Math.round(diff);
  }

  // TODO: Подобрать подходящее название
  static dateLeft(days) {
    const DAYS_ON_MONTH = 30;
    const monthsLeft = Number.parseInt(days / DAYS_ON_MONTH);

    if (days === 0) {
      return "менее 24 часов";
    } else if (monthsLeft > 0) {
      return monthsLeft + " " + declOfNum(monthsLeft, ["месяц", "месяца", "месяцев"]);
    } else {
      return days + " " + declOfNum(days, ["день", "дня", "дней"]);
    }
  }

  static formatDateTime(
    date,
    { shortMonth = true, divider = " в ", fallback = "—", timeFirst = false, showTime = true } = {}
  ) {
    if (date === null) return fallback;

    const _date = DateHelper.parseDate(date);

    if (Number.isNaN(_date.getSeconds())) return fallback;

    const year = _date.getFullYear();

    let month = _date.getMonth();
    month = shortMonth ? DateHelper.months.shorthand[month] : DateHelper.months.longhand[month];

    const day = _date.getDate();

    const hour = _date.getHours();
    const minutes = _date.getMinutes().toString().padStart(2, "0");

    let time = `${hour}:${minutes}`;

    if (!showTime) {
      time = "";
      divider = "";
    }

    return timeFirst ? `${time}${divider}${day} ${month} ${year}` : `${day} ${month} ${year}${divider}${time}`;
  }
}
