import Vue from "vue";
import { router } from "./router";
import { store } from "./store";
import { vuetify } from "./plugins/vuetify";

import "~/assets/styles/main.scss";

import "~/core/app";

new Vue({
  router,
  store,
  vuetify,
  render: h => h("router-view")
}).$mount("#app");
