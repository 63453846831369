import { RouteGroup, RouteNavSchema } from "./common";

export const logsRoutes = new RouteGroup({ prefix: "logs" })
  .addRoute({ path: "index", name: "logs-index", component: () => import("~/pages/logs") })
  .addRoute({ path: ":id", name: "logs-id", component: () => import("~/pages/logs/_id") });

export const logsNav = new RouteNavSchema({
  icon: "mdi-book-information-variant",
  label: "Логи действий",
  to: { name: "logs-index" }
});
