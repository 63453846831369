export const sort = {
  computed: {
    sort() {
      let sort;

      if (this.options?.sortBy?.[0]) {
        const [by] = this.options.sortBy;
        const [sortDesc] = this.options.sortDesc;

        const order = sortDesc ? "desc" : "asc";

        sort = { by, order };
      }

      return sort;
    }
  }
};
