<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="textValue"
        :label="label"
        placeholder="Дата"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      >
        <template v-if="clearable" v-slot:append>
          <v-btn icon @click="$emit('clear')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>

    <v-date-picker v-model="date" />
  </v-menu>
</template>

<script>
import { DateHelper } from "~/core/date";

export default {
  props: {
    value: {
      required: true,
      validator(value) {
        return typeof value === "string" || value === null;
      }
    },
    label: {
      type: String,
      required: true
    },
    clearable: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.isMenuOpen = false;
      }
    },
    textValue() {
      if (!this.value) return "";

      return DateHelper.getHumanFriendlyDate(this.date);
    }
  }
};
</script>
