<template>
  <v-list-group v-if="link.children.length" :prepend-icon="link.icon" color="accent">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>{{ link.label }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <v-list-item v-for="(child, ind2) of link.children" :key="ind2" class="pl-6" link :to="child.to">
      <v-list-item-title>{{ child.label }}</v-list-item-title>
    </v-list-item>
  </v-list-group>

  <v-list-item v-else link :to="link.to">
    <v-list-item-icon>
      <v-icon>{{ link.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-title>{{ link.label }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "MainNavigationLink",
  props: {
    link: {
      type: Object,
      required: true
    }
  }
};
</script>
