<template>
  <v-menu bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-avatar color="accent" size="40" v-bind="attrs" v-on="on">
        <span class="font-weight-bold">{{ shortName }}</span>
      </v-avatar>
    </template>

    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <h3>{{ name }}</h3>
          <p class="caption mt-1">{{ user.email }}</p>

          <v-divider class="my-3" />

          <div class="px-3">
            <v-switch v-model="$vuetify.theme.dark" class="mt-0" label="Темная тема" :hide-details="true" />
          </div>

          <v-divider class="my-3" />
          <v-btn depressed rounded text @click="$auth.logOut">Выйти</v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user.user;
    },

    shortName() {
      const { name, lastName, email } = this.user;

      if (name && lastName) {
        return name.slice(0, 1) + lastName.slice(0, 1);
      } else {
        return email.slice(0, 2).toUpperCase();
      }
    },
    name() {
      const { name, lastName } = this.user;

      if (name && lastName) {
        return `${name} ${lastName.slice(0, 1)}.`;
      } else if (name && !lastName) {
        return name;
      } else if (lastName && !name) {
        return lastName;
      }

      return "<Имя не указано>";
    }
  }
};
</script>
