import deepEqual from "deep-equal";
import { debounce } from "~/core/helpers";

export const params = {
  created() {
    this.debouncedFetch = debounce(this.fetch, 250);
  },
  computed: {
    params() {
      return {
        offset: this.offset,
        limit: this.limit,
        sort: this.sort,
        ...this.additionalParams
      };
    },
    additionalParams() {
      return {
        ...this.tableParams
      };
    }
  },
  watch: {
    params: {
      deep: true,
      handler(newVal, oldVal) {
        if (deepEqual(newVal, oldVal, { strict: true })) return;

        this.debouncedFetch();
      }
    }
  }
};
