import deepEqual from "deep-equal";

export const pagination = {
  computed: {
    pageCount() {
      return Math.ceil(this.content.count / this.options.itemsPerPage);
    },

    limit() {
      return this.options.itemsPerPage;
    },
    offset() {
      return (this.options.page - 1) * this.limit ?? 0;
    }
  },
  watch: {
    additionalParams: {
      deep: true,
      handler(newVal, oldVal) {
        if (!deepEqual(newVal, oldVal, { strict: true })) {
          this.options.page = 1;
        }
      }
    }
  }
};
