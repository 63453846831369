import { RouteGroup, RouteNavSchema } from "./common";

export const recommendationsRoute = new RouteGroup({ prefix: "recommendations" })
  .addRoute({
    path: "index",
    name: "recommendations-index",
    component: () => import("~/pages/recommendations")
  })
  .addRoute({
    path: "mistakes",
    name: "recommendations-mistakes",
    component: () => import("~/pages/recommendations/mistakes")
  });

export const recommendationsNav = new RouteNavSchema({
  icon: "mdi-head-flash",
  label: "Рекомендации"
})
  .addChild({ label: "Список", to: { name: "recommendations-index" } })
  .addChild({ label: "Ошибки", to: { name: "recommendations-mistakes" } });
