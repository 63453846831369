import Vue from "vue";

import "./ui";
import "./filters";

import { $axios, injectAuth } from "~/core/axios";
import { $auth, AuthModule } from "~/core/auth-module";
import { Snackbar } from "~/plugins/snackbar";
import { store } from "~/store";

Vue.config.productionTip = false;

Vue.prototype.$axios = $axios;
Vue.prototype.$auth = $auth;
Vue.prototype.$snackbar = new Snackbar(store.dispatch);

injectAuth(AuthModule.origin, $auth);
