<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :value="textValue" :label="label" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on">
        <template v-slot:append>
          <div class="d-inline-flex">
            <v-btn v-if="internalValue.length === 1" icon small @click="toggleBorder">
              <v-icon small>mdi-calendar-edit</v-icon>
            </v-btn>

            <v-btn v-if="textValue" icon small @click="reset">
              <v-icon small>mdi-close-thick</v-icon>
            </v-btn>
          </div>
        </template>
      </v-text-field>
    </template>

    <v-date-picker v-model="internalValue" range />
  </v-menu>
</template>

<script>
import { DateHelper } from "~/core/date";

export default {
  props: {
    value: {
      type: Object,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      default: "Label"
    }
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    internalValue: {
      get() {
        return Object.values(this.value).filter(date => date);
      },
      set(value) {
        let newVal = { min: null, max: null };

        if (value.length === 1) {
          newVal = { min: value[0], max: null };
        } else if (value.length === 2) {
          const isFullRange = value.every(item => item !== null);

          if (isFullRange) {
            value = value.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
          }

          newVal = { min: value[0], max: value[1] };
        }

        this.$emit("input", newVal);
      }
    },
    textValue() {
      const { min, max } = this.value;

      const f = DateHelper.getHumanFriendlyDate;

      let text = "";

      if (min && max) {
        text = `${f(min)} — ${f(max)}`;
      } else if (min) {
        text = `от ${f(min)}`;
      } else if (max) {
        text = `до ${f(max)}`;
      }

      return text;
    }
  },
  methods: {
    reset() {
      this.internalValue = [];
    },
    toggleBorder() {
      if (this.internalValue.length !== 1) return;

      const { min, max } = this.value;

      if (min) {
        this.internalValue = [null, min];
      } else {
        this.internalValue = [max];
      }
    }
  }
};
</script>
