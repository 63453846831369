import { RouteGroup, RouteNavSchema } from "./common";

export const compareCompilationsRoutes = new RouteGroup({ prefix: "compare-compilations" })
  .addRoute({
    path: "index",
    name: "compare-compilations-index",
    component: () => import("~/pages/compare-compilations")
  })
  .addRoute({
    path: "create",
    name: "compare-compilations-create",
    component: () => import("~/pages/compare-compilations/create")
  })
  .addRoute({
    path: ":id",
    name: "compare-compilations-id",
    component: () => import("~/pages/compare-compilations/_id")
  });

export const compareCompilationsNav = new RouteNavSchema({ icon: "mdi-checkbox-multiple-blank", label: "Сравнения" })
  .addChild({ label: "Список", to: { name: "compare-compilations-index" } })
  .addChild({ label: "Создать подборку", to: { name: "compare-compilations-create" } });
