<template>
  <div class="data-table" :class="classes">
    <v-data-table
      class="elevation-3"
      :headers="headers"
      :items="content.data"
      :item-key="itemKey"
      :server-items-length="content.count"
      :loading="loading"
      :options.sync="options"
      :footer-props="footerProps"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      v-on="on"
    >
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-data-table>

    <div v-if="pageCount" class="text-center pt-2">
      <v-pagination v-model="options.page" :length="pageCount" total-visible="10" />
    </div>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { params } from "./params";
import { view } from "./view";
import { sort } from "./sort";
import { events } from "./events";
import { pagination } from "./pagination";

export default {
  mixins: [params, view, sort, events, pagination],
  props: {
    /** Базовый url данных для таблицы */
    url: {
      type: String,
      required: true
    },
    /** Список заголовков таблицы */
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    /** Уникальный индентификатор записи */
    itemKey: {
      type: String,
      default: "id"
    },
    /** Дополнительные параметры запроса (напр. фильтры) */
    tableParams: {
      type: Object,
      default() {
        return {};
      }
    },
    clickableRows: {
      type: Boolean,
      default: false
    },
    sortBy: {
      type: String,
      default: ""
    },
    sortDesc: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      content: {
        count: 0,
        available: 0,
        limit: 0,
        offset: 0,
        sort: {
          by: null,
          order: null
        },
        data: []
      },

      options: {},

      loading: true,
      footerProps: {
        "items-per-page-options": [20, 50, 100]
      }
    };
  },
  methods: {
    async fetch() {
      this.loading = true;

      try {
        this.content = await this.$axios.$get(this.url, {
          params: this.params
        });
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Данные не получены: " + html);
      } finally {
        this.loading = false;
      }
    },

    update() {
      return this.fetch();
    }
  }
};
</script>

<style lang="scss">
.data-table {
  .v-data-footer__icons-before,
  .v-data-footer__icons-after {
    display: none;
  }

  &_clickable-rows {
    tbody tr {
      cursor: pointer;
    }
  }
}
</style>
