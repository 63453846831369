import { RouteGroup, RouteNavSchema } from "./common";

export const indicesRoutes = new RouteGroup({ prefix: "indices" })
  .addRoute({ path: "index", name: "indices-index", component: () => import("~/pages/indices") })
  .addRoute({ path: ":ticker", name: "indices-ticker", component: () => import("~/pages/indices/_ticker") });

export const indicesNav = new RouteNavSchema({
  icon: "mdi-chart-timeline-variant-shimmer",
  label: "Индексы",
  to: { name: "indices-index" }
});
