<template>
  <v-app>
    <v-main style="background-color: #eee">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex md6 lg4 xl3>
            <login-form />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { layoutUserFetch } from "~/mixins/layout-user-fetch";

import LoginForm from "~/components/login/login-form";

export default {
  mixins: [layoutUserFetch],
  watch: {
    userStatus(status) {
      if (status === "authorized") {
        this.$router.replace({ name: "panel-index" });
      }
    }
  },
  components: {
    LoginForm
  }
};
</script>
