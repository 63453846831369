<template>
  <v-sheet class="pa-3" height="450px" style="overflow-y: scroll">
    <v-container>
      <v-text-field v-model="tableParams.title" label="Поиск" autocomplete="off" clearable />

      <div v-if="active" class="mt-2">
        <data-table url="stocks" item-key="ticker" :headers="headers" :table-params="tableParams">
          <template v-slot:[`item.ticker`]="{ item }">
            <div class="d-flex align-center py-2">
              <div>
                <v-img class="rounded-circle" :src="item.logo" width="44" height="44" />
              </div>

              <div class="ml-4">
                {{ item.ticker }}
              </div>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn color="accent" @click="$emit('selected', item)">Выбрать</v-btn>
          </template>
        </data-table>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      headers: [
        { text: "Тикер", value: "ticker", sortable: false },
        { text: "Компания", value: "company", sortable: false },
        { text: "Действия", value: "actions", sortable: false }
      ],

      tableParams: {
        title: "",
        sort: { by: "title" }
      }
    };
  },
  watch: {
    active() {
      this.reset();
    }
  },
  methods: {
    reset() {
      this.tableParams.title = "";
      this.stocks = [];
    }
  }
};
</script>
