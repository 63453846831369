const allowEvents = ["click:row"];

export const events = {
  computed: {
    on: {
      get() {
        return allowEvents.reduce((acc, event) => ({ ...acc, [event]: this.eventEmitter.bind(null, event) }), {});
      }
    }
  },
  methods: {
    eventEmitter(event, ...args) {
      this.$emit(event, ...args);
    }
  }
};
