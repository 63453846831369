import Vue from "vue";
import Vuex from "vuex";

import { user } from "./user";
import { snackbar } from "./snackbar";
import { dictionary } from "./dictionary";
import { forms } from "./forms";

Vue.use(Vuex);

export const store = new Vuex.Store({
  actions: {
    async fetchDictionaries({ dispatch }) {
      await Promise.all([dispatch("dictionary/fetchProducts")]);
    }
  },
  modules: {
    user,
    snackbar,
    dictionary,
    forms
  }
});
