import Vue from "vue";

import DataTable from "~/components/shared/data-table";
import BtnBack from "~/components/shared/btn-back";
import CodeBlock from "~/components/shared/code-block";

import StockPicker from "~/components/shared/stock-picker";
import StockTablePicker from "~/components/shared/stock-table-picker";
import UnsplashPicker from "~/components/shared/unsplash-picker";

import WysiwygEditor from "~/components/shared/wysiwyg-editor";
import DateRangePicker from "~/components/shared/date-range-picker";
import DatePicker from "~/components/shared/date-picker";

Vue.component("data-table", DataTable);
Vue.component("btn-back", BtnBack);
Vue.component("code-block", CodeBlock);

Vue.component("stock-picker", StockPicker);
Vue.component("stock-table-picker", StockTablePicker);
Vue.component("unsplash-picker", UnsplashPicker);

Vue.component("wysiwyg-editor", WysiwygEditor);

Vue.component("date-range-picker", DateRangePicker);
Vue.component("date-picker", DatePicker);
