<template>
  <div>
    <date-menu v-model="date" :label="label" :clearable="clearable" @clear="clear" />
    <time-menu v-if="date && useTime" v-model="time" />
  </div>
</template>

<script>
import DateMenu from "./date-menu";
import TimeMenu from "./time-menu";

export default {
  props: {
    value: {
      validator(value) {
        return typeof value === "string" || value === null;
      },
      default() {
        return "";
      }
    },
    label: {
      type: String,
      default: "Label"
    },
    clearable: {
      type: Boolean,
      default: false
    },

    useTime: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    splitedValue() {
      if (!this.useTime) {
        return { date: this.value, time: null };
      }

      const defaultTime = "23:59:59";

      try {
        const [date, time] = this.value.split(" ");

        return { date, time: time ?? defaultTime };
      } catch {
        return { date: null, time: defaultTime };
      }
    },

    date: {
      get() {
        return this.splitedValue.date;
      },
      set(value) {
        this.handleChange("date", value);
      }
    },
    time: {
      get() {
        return this.splitedValue.time;
      },
      set(value) {
        this.handleChange("time", value);
      }
    }
  },
  methods: {
    handleChange(field, value) {
      if (this.useTime) {
        const nextValue = { ...this.splitedValue };

        nextValue[field] = value;

        this.$emit("input", `${nextValue.date} ${nextValue.time}`);
      } else if (field === "date") {
        this.$emit("input", value);
      }
    },

    clear() {
      this.$emit("input", null);
    }
  },
  components: {
    DateMenu,
    TimeMenu
  }
};
</script>
