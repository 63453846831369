<template>
  <v-sheet class="pa-4" elevation="3">
    <div class="d-flex justify-space-between align-center">
      <h3>
        <slot name="title">
          <div>Акции</div>
        </slot>
      </h3>

      <stock-picker @selected="handleSelect" />
    </div>

    <div class="mt-4">
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left">Тикер</th>
            <th class="text-left">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in value" :key="formatTitle(item)">
            <td>{{ formatTitle(item) }}</td>
            <td>
              <v-btn icon color="error" @click="removeItem(item)">
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <div v-if="value.length === 0" class="text-center py-2">Ничего не выбрано</div>
    </div>
  </v-sheet>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    },
    maxCount: {
      type: Number,
      default: 3
    },
    mode: {
      type: String,
      default: "ticker",
      validator(mode) {
        return ["ticker", "stock"].includes(mode);
      }
    }
  },
  computed: {
    isTickerMode() {
      return this.mode === "ticker";
    }
  },
  methods: {
    handleSelect(item) {
      let newVal = [...this.value];
      const selectedItem = this.isTickerMode ? item.ticker : item;

      if (this.isTickerMode) {
        newVal = Array.from(new Set([...newVal, selectedItem]));
      } else {
        if (!newVal.find(stock => stock.ticker === item.ticker)) {
          newVal.push(item);
        }
      }

      if (newVal.length > this.maxCount) {
        newVal.length = this.maxCount;

        this.$snackbar.message("Выбрано максимальное количество элементов: " + this.maxCount);
      }

      this.$emit("input", newVal);
    },

    removeItem(item) {
      const newVal = this.value.filter(row => {
        if (this.isTickerMode) {
          return row !== item;
        } else {
          return row.ticker !== item.ticker;
        }
      });

      this.$emit("input", newVal);
    },

    formatTitle(item) {
      return this.isTickerMode ? item : item.ticker;
    }
  }
};
</script>
