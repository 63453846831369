import { $axios } from "~/core/axios";

const STORAGE_KEY = "token";

export class TokenService {
  #refreshPromise;
  #expires_in;

  constructor() {
    this.init();
  }

  get tokens() {
    return JSON.parse(localStorage.getItem(STORAGE_KEY)) || {};
  }

  set tokens(tokens) {
    const dateNow = Date.now();
    const offset = tokens.expires_in * 1000;
    const expires_in = dateNow + offset;

    this.#expires_in = expires_in;
    tokens.expires_in = expires_in;

    localStorage.setItem(STORAGE_KEY, JSON.stringify(tokens));
    $axios.setToken(tokens.access_token);
  }

  init() {
    const { access_token, expires_in } = this.tokens;

    if (access_token) {
      this.#expires_in = expires_in;

      $axios.setToken(access_token);
    }
  }

  /** Попытка получени пары токенов */
  async getByAuthData(payload) {
    try {
      this.tokens = await this.auth(payload);
    } catch (e) {
      throw e?.response?.data?.error_description ?? "Произошла неизвестная ошибка";
    }
  }

  /** Получение пары токенов */
  async auth({ email, password }) {
    return $axios.$post(process.env.VUE_APP_AUTH_URL + "oauth/token", {
      client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
      grant_type: "password",
      username: email,
      password
    });
  }

  /** Обновление пары токенов */
  async refresh() {
    if (!this.#refreshPromise) {
      this.#refreshPromise = this.refreshTokens();
    }

    return this.#refreshPromise;
  }

  async refreshTokens() {
    try {
      const { refresh_token } = this.tokens;

      const authData = await $axios.$post(process.env.VUE_APP_AUTH_URL + "oauth/token", {
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        grant_type: "refresh_token",
        refresh_token
      });

      this.tokens = authData;

      return authData.access_token;
    } catch {
      this.remove();

      return false;
    } finally {
      this.#refreshPromise = null;
    }
  }

  /**
   * @returns { boolean } Флаг истечения жизни токена
   */
  isTokenExpired() {
    return Date.now() >= this.#expires_in;
  }

  /** Удаление токенов из LocalStorage и заголовков запроса  */
  remove() {
    localStorage.removeItem(STORAGE_KEY);
    $axios.setToken(false);
  }
}
