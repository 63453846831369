<template>
  <div class="d-inline-flex">
    <v-btn @click="active = !active">
      <v-icon>mdi-camera-party-mode</v-icon>
    </v-btn>

    <v-bottom-sheet v-model="active" scrollable>
      <photo-picker :active="active" @select-photo="handleSelect" />
    </v-bottom-sheet>
  </div>
</template>

<script>
import PhotoPicker from "./picker";

export default {
  data() {
    return {
      active: false
    };
  },
  methods: {
    handleSelect(photo) {
      this.$emit("selected", photo);

      this.active = false;
    }
  },
  components: {
    PhotoPicker
  }
};
</script>
