import { RouteGroup, RouteNavSchema } from "./common";

export const settingsRoutes = new RouteGroup({ prefix: "settings" }).addRoute({
  path: "index",
  name: "settings-index",
  component: () => import("~/pages/settings")
});

export const settingsNav = new RouteNavSchema({
  icon: "mdi-cog",
  label: "Настройки",
  to: { name: "settings-index" }
});
