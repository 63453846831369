import { RouteGroup, RouteNavSchema } from "./common";

export const currenciesRoutes = new RouteGroup({ prefix: "currencies" })
  .addRoute({ path: "index", name: "currencies-index", component: () => import("~/pages/currencies") })
  .addRoute({
    path: ":alphaCode",
    name: "currencies-alphaCode",
    component: () => import("~/pages/currencies/_alphaCode")
  });

export const currenciesNav = new RouteNavSchema({
  icon: "mdi-currency-usd",
  label: "Валюты",
  to: { name: "currencies-index" }
});
