import { RouteGroup, RouteNavSchema } from "./common";

export const usersRoutes = new RouteGroup({ prefix: "users" })
  .addRoute({ path: "index", name: "users-index", component: () => import("~/pages/users") })
  .addRoute({ path: "invites", name: "users-invites", component: () => import("~/pages/users/invites") })
  .addRoute({ path: "create", name: "users-create", component: () => import("~/pages/users/create") })
  .addRoute({ path: ":id", name: "users-id", component: () => import("~/pages/users/_id") });

export const usersNav = new RouteNavSchema({ icon: "mdi-account-multiple", label: "Пользователи" })
  .addChild({ label: "Список", to: { name: "users-index" } })
  .addChild({ label: "Инвайты", to: { name: "users-invites" } })
  .addChild({ label: "Создать пользователя", to: { name: "users-create" } });
