const analystActionEnum = {
  reiterated: "Назначил",
  maintained: "Подтвердил",
  initiated: "Установил",
  upgraded: "Повысил",
  downgraded: "Понизил"
};

export const analystAction = value => {
  return analystActionEnum[value] ?? "—";
};
