import { RouteGroup, RouteNavSchema } from "./common";

export const cryptoRoutes = new RouteGroup({ prefix: "crypto" })
  .addRoute({
    path: "index",
    name: "crypto-index",
    component: () => import("~/pages/crypto")
  })
  .addRoute({
    path: ":code",
    name: "crypto-code",
    component: () => import("~/pages/crypto/_code")
  });

export const cryptoNav = new RouteNavSchema({
  icon: "mdi-bitcoin",
  label: "Криптовалюты",
  to: { name: "crypto-index" }
});
