export class RouteGroup {
  #prefix = "";
  #routes = [];

  constructor(opts) {
    this.#prefix = opts.prefix;
  }

  getRoutes() {
    return this.#routes;
  }

  addRoute(route) {
    route.path = `${this.#prefix}/${route.path}`;

    this.#routes.push(route);

    return this;
  }
}

export class RouteNavSchema {
  #schema = { icon: "", label: "", to: undefined, children: [] };

  constructor({ icon = "mdi-alert-box", label = "Link", to = undefined } = {}) {
    this.#schema.icon = icon;
    this.#schema.label = label;
    this.#schema.to = to;
  }

  getSchema() {
    return this.#schema;
  }

  addChild(child) {
    this.#schema.children.push(child);

    return this;
  }
}
