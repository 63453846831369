<template>
  <v-card elevation="4">
    <v-card-title>Авторизация</v-card-title>

    <div class="px-4 pb-4">
      <v-form v-model="valid" lazy-validation @submit.prevent="login">
        <v-text-field v-model="email" :rules="emailRules" label="E-mail" required />
        <v-text-field v-model="password" :rules="passwordRules" label="Пароль" type="password" required />

        <v-layout align-center justify-center>
          <v-btn type="submit" color="success" :disabled="!valid" :loading="loading">Войти</v-btn>
        </v-layout>

        <v-alert v-if="errorText" class="mt-4" dense outlined type="error">
          {{ errorText }}
        </v-alert>
      </v-form>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      errorText: null,

      valid: true,
      email: "",
      emailRules: [v => !!v || "Укажите e-mail"],
      password: "",
      passwordRules: [v => !!v || "Укажите пароль"]
    };
  },
  methods: {
    async login() {
      this.loading = true;
      this.errorText = null;

      try {
        await this.$auth.signIn({ email: this.email, password: this.password });
        await this.$store.dispatch("user/fetch");
      } catch (e) {
        this.errorText = e;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
