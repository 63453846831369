import { RouteGroup, RouteNavSchema } from "./common";

export const investCompainesRoute = new RouteGroup({ prefix: "invest-companies" })
  .addRoute({
    path: "index",
    name: "invest-companies-index",
    component: () => import("~/pages/invest-companies")
  })
  .addRoute({ path: ":id", name: "invest-companies-id", component: () => import("~/pages/invest-companies/_id") });

export const investCompaniesNav = new RouteNavSchema({
  icon: "mdi-home",
  label: "Инвест-дома",
  to: { name: "invest-companies-index" }
});
