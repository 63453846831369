import { RouteGroup, RouteNavSchema } from "./common";

export const expertsRoute = new RouteGroup({ prefix: "experts" })
  .addRoute({
    path: "index",
    name: "experts-index",
    component: () => import("~/pages/experts")
  })
  .addRoute({ path: "doubles", name: "experts-doubles", component: () => import("~/pages/experts/doubles") })
  .addRoute({ path: ":id", name: "experts-id", component: () => import("~/pages/experts/_id") });

export const expertsNav = new RouteNavSchema({
  icon: "mdi-account-tie",
  label: "Эксперты"
})
  .addChild({ label: "Список", to: { name: "experts-index" } })
  .addChild({ label: "Дубли", to: { name: "experts-doubles" } });
