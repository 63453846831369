import { TokenService } from "./token-service";

export class AuthModule {
  static origin = new URL(process.env.VUE_APP_AUTH_URL).origin;

  constructor() {
    this.tokenService = new TokenService();

    this.logOut = this.logOut.bind(this);
  }

  signIn(payload) {
    return this.tokenService.getByAuthData(payload);
  }

  refreshToken() {
    return this.tokenService.refresh();
  }

  logOut({ redirect = true } = {}) {
    this.tokenService.remove();

    if (redirect) window.location.href = "/login";
  }
}

export const $auth = new AuthModule();
