import Vue from "vue";
import Vuetify from "vuetify/lib";

import { Ripple } from "vuetify/lib/directives";

import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

import colors from "vuetify/es5/util/colors";
import ru from "vuetify/es5/locale/ru";

export const vuetify = new Vuetify({
  lang: {
    locales: { ru },
    current: "ru"
  },
  theme: {
    themes: {
      light: {
        primary: "#4d6dd5",
        secondary: colors.blueGrey.base,
        accent: colors.green.base,
        error: colors.red.base,
        warning: colors.amber.base,
        info: colors.lightBlue.base,
        success: colors.lightGreen.base
      },
      dark: {
        primary: "#363636",
        secondary: "#888",
        accent: colors.green.base,
        error: colors.red.darken2,
        warning: colors.amber.darken2,
        info: colors.lightBlue.darken2,
        success: colors.lightGreen.darken2
      }
    }
  }
});

Vue.use(Vuetify, {
  directives: {
    Ripple
  }
});

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: "mdi"
});
