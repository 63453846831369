export function isObject(val) {
  return val != null && typeof val === "object" && Array.isArray(val) === false && !(val instanceof File);
}

export const sleep = m => new Promise(r => setTimeout(r, m));

/** Склонение существительных
 * Использование:
 *   declOfNum(10, ["яблоко", "яблока", "яблок"])
 *                     1         2         5
 */
export function declOfNum(n, titles) {
  n = +n;
  return titles[
    n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
  ];
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function toFormData(payload) {
  const formData = new FormData();

  const push = (key, value) => {
    if (isObject(value) || Array.isArray(value)) {
      for (const [index, subValue] of Object.entries(value)) {
        push(`${key}[${index}]`, subValue);
      }
    } else if (value !== null) {
      formData.append(key, value);
    }
  };

  for (const key in payload) {
    push(key, payload[key]);
  }

  return formData;
}

/** Форматирование строкового фильтра в массив */
export function stringToMultipleFilter(payload) {
  if (typeof payload !== "string") return undefined;

  return payload
    .split(",")
    .map(item => item.trim())
    .filter(item => item);
}

export function encodeFileToBase64(file) {
  if (file === null || file === undefined) return null;

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = e => {
      const base64String = e.target.result.replace("data:", "").replace(/^.+,/, "");

      resolve(base64String);
    };

    fileReader.onerror = error => {
      reject(error);
    };
  });
}
