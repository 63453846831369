import { RouteGroup, RouteNavSchema } from "./common";

export const compilationsRoutes = new RouteGroup({ prefix: "compilations" })
  .addRoute({ path: "index", name: "compilations-index", component: () => import("~/pages/compilations") })
  .addRoute({ path: "create", name: "compilations-create", component: () => import("~/pages/compilations/create") })
  .addRoute({ path: ":id", name: "compilations-id", component: () => import("~/pages/compilations/_id") });

export const compilationsNav = new RouteNavSchema({ icon: "mdi-file-multiple", label: "Подборки акций" })
  .addChild({ label: "Список", to: { name: "compilations-index" } })
  .addChild({ label: "Создать подборку", to: { name: "compilations-create" } });
