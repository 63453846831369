<template>
  <div>
    <div class="mb-2">
      <slot name="title" />
    </div>
    <tiptap-vuetify v-model="content" :placeholder="placeholder" :extensions="extensions" :disabled="disabled" />
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from "tiptap-vuetify";

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Текст..."
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      extensions: [
        Bold,
        Italic,
        Strike,
        Underline,
        Link,
        [
          Heading,
          {
            options: {
              levels: [2, 3]
            }
          }
        ],
        Paragraph,
        Blockquote,
        BulletList,
        OrderedList,
        HorizontalRule,
        Code,
        History,

        // service
        ListItem,
        HardBreak
      ]
    };
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  components: {
    TiptapVuetify
  }
};
</script>
