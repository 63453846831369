<template>
  <v-sheet class="pa-3" height="450px" style="overflow-y: scroll">
    <v-container>
      <v-text-field v-model="query" label="Поиск" autocomplete="off" clearable @keyup.enter="search">
        <template v-slot:append-outer>
          <v-btn-toggle v-model="orientation" class="mr-2" dense>
            <v-btn>
              <v-icon>mdi-image-area</v-icon>
            </v-btn>
            <v-btn>
              <v-icon>mdi-image-album</v-icon>
            </v-btn>
          </v-btn-toggle>

          <v-btn :loading="loading" @click="search">
            <v-icon>mdi-file-find</v-icon>
          </v-btn>
        </template>
      </v-text-field>

      <div class="d-flex flex-wrap mt-2">
        <v-img
          v-for="photo of photos"
          :key="photo.id"
          class="mb-2 mr-2"
          :src="photo.urls.small"
          contain
          max-width="250"
          @click="$emit('select-photo', photo)"
        />
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
const unsplash = {
  url: "https://api.unsplash.com/photos/random",
  cliendId: "26777",
  accessKey: "dfe84ef4ea369a4c631ed5802b9dcf6baa6db85ded4330cd2dae0f96a4996fad"
};

export default {
  props: {
    active: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,

      query: "",
      orientation: 0,
      orientationMap: {
        0: "landscape",
        1: "portrait"
      },

      photos: []
    };
  },
  watch: {
    active() {
      this.reset();
    }
  },
  methods: {
    async search() {
      this.loading = true;

      try {
        this.photos = await this.$axios.$get("https://api.unsplash.com/photos/random", {
          headers: {
            Authorization: `${unsplash.cliendId} ${unsplash.accessKey}`
          },
          params: {
            client_id: unsplash.accessKey,
            query: this.query,
            orientation: this.orientationMap[this.orientation],
            count: 30
          }
        });
      } catch (e) {
        this.$snackbar.error("Неудача: " + e?.message);
      } finally {
        this.loading = false;
      }
    },

    reset() {
      this.loading = false;
      this.query = "";
      this.photos = [];
    }
  }
};
</script>
