export const consensusList = {
  StrongBuy: "Активно покупать",

  Buy: "Покупать",
  buy: "Покупать",
  ModerateBuy: "Покупать",

  Hold: "Держать",
  hold: "Держать",
  Neutral: "Держать",

  Sell: "Продавать",
  sell: "Продавать",
  ModerateSell: "Продавать",

  StrongSell: "Активно продавать"
};

export const formatConsensus = value => {
  return consensusList[value] ?? "Нет данных";
};
