<template>
  <v-btn :color="color" dark link :to="to">
    <v-icon dark left>mdi-arrow-left</v-icon>
    <span>Назад</span>
  </v-btn>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "grey"
    },
    to: {
      type: Object,
      required: true
    }
  }
};
</script>
