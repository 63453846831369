import { RouteGroup, RouteNavSchema } from "./common";

export const mobileRoutes = new RouteGroup({ prefix: "mobile" })
  .addRoute({
    path: "index",
    name: "mobile-index",
    component: () => import("~/pages/mobile")
  })
  .addRoute({
    path: "send-push",
    name: "mobile-send-push",
    component: () => import("~/pages/mobile/send-push")
  });

export const mobileNav = new RouteNavSchema({ icon: "mdi-cellphone", label: "Приложение" })
  .addChild({
    label: "Push-уведомления",
    to: { name: "mobile-send-push" }
  })
  .addChild({
    label: "Настройки",
    to: { name: "mobile-index" }
  });
