import { RouteGroup, RouteNavSchema } from "./common";

export const stocksRoutes = new RouteGroup({ prefix: "stocks" })
  .addRoute({ path: "index", name: "stocks-index", component: () => import("~/pages/stocks") })
  .addRoute({ path: "matching", name: "stocks-matching", component: () => import("~/pages/stocks/matching") })
  .addRoute({ path: "splits", name: "stocks-splits", component: () => import("~/pages/stocks/splits") })
  .addRoute({ path: ":ticker", name: "stocks-ticker", component: () => import("~/pages/stocks/_ticker") });

export const stocksNav = new RouteNavSchema({ icon: "mdi-cash-multiple", label: "Акции" })
  .addChild({ label: "Список", to: { name: "stocks-index" } })
  .addChild({ label: "Матчинг", to: { name: "stocks-matching" } })
  .addChild({ label: "Сплиты", to: { name: "stocks-splits" } });
