import { RouteGroup, RouteNavSchema } from "./common";

export const countryRoute = new RouteGroup({ prefix: "countries" })
  .addRoute({
    path: "index",
    name: "countries-index",
    component: () => import("~/pages/countries")
  })
  .addRoute({ path: ":code", name: "countries-code", component: () => import("~/pages/countries/_code") });

export const countriesNav = new RouteNavSchema({ icon: "mdi-flag", label: "Страны", to: { name: "countries-index" } });
