<template>
  <div class="code-block pa-4">
    <div class="code-block__copy" @click="copy">
      <v-icon dark>mdi-content-copy</v-icon>
    </div>

    <pre>{{ content }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    content() {
      return this.format(this.value);
    }
  },
  methods: {
    format() {
      return JSON.stringify(this.value, null, 2);
    },

    async copy() {
      try {
        await navigator.clipboard.writeText(this.content);

        this.$snackbar.success("Скопировано");
      } catch (e) {
        const errorText = e.message || e;

        this.$snackbar.error(`Не скопировано: <div>${errorText}</div>`);
      }
    }
  }
};
</script>

<style lang="scss">
.code-block {
  position: relative;
  background-color: #9e9e9e;
  color: #fff;

  &__copy {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
</style>
