import { RouteGroup, RouteNavSchema } from "./common";

export const definitionsRoutes = new RouteGroup({ prefix: "definitions" })
  .addRoute({ path: "index", name: "definitions-index", component: () => import("~/pages/definitions") })
  .addRoute({ path: "create", name: "definitions-create", component: () => import("~/pages/definitions/create") })
  .addRoute({ path: ":id", name: "definitions-id", component: () => import("~/pages/definitions/_id") });

export const definitionsNav = new RouteNavSchema({ icon: "mdi-book-open-variant", label: "Словарь" })
  .addChild({ label: "Список", to: { name: "definitions-index" } })
  .addChild({ label: "Создать термин", to: { name: "definitions-create" } });
